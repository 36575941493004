<template>
  <!-- 答案解析界面 -->
  <div class="checkOutAnswer">
    <van-nav-bar :title="examinTitle"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true" />
    <div class="fixedH"
         style="height: 1.22667rem"></div>
    <div class="banner"></div>
    <div class="content"
         v-for="(bigType, qtIdx) in qsList"
         :key="qtIdx">
      <!-- 题型信息 -->
      <div class="qsType">
        <span>{{ bigOrder[qtIdx] }}</span>
        <span class="typeName">{{ bigType.qsType }}</span>
        <span class="grade"
              v-if="
            bigType.qsType == '单选题' ||
            bigType.qsType == '多选题' ||
            bigType.qsType == '判断题'
          ">
          {{
            `(共${bigType.qsDetails.length}题，每题${bigType.mark}分，共${
              bigType.qsDetails.length * bigType.mark
            }分)`
          }}
        </span>
        <span class="grade"
              v-if="bigType.qsType == '填空题'">
          {{
            `(共${bigType.qsDetails.length}题，每空${bigType.mark}分，共${
              bigType.mark * tkNum
            }分)`
          }}
        </span>
        <span class="grade"
              v-if="bigType.qsType == '简答题'">
          {{ `(共${bigType.qsDetails.length}题，共${brief}分)` }}
        </span>
      </div>
      <!-- 题目内容详情 -->
      <div class="qsContent"
           v-for="(item, index) in bigType.qsDetails"
           :key="index">
        <div class="qsTit">
          {{ `${index + 1}.${item.qsTit}` }}
          <span style="color: red;">{{`（${item.get}分）`}}</span>
          <span v-if="bigType.qsType == '填空题'"
                style="margin-left: 15px; color: #2b8df0">{{
              `(${bigType.mark * (item.qsTit.split("____").length - 1)}分)`
            }}</span>
          <span v-if="bigType.qsType == '简答题'"
                style="margin-left: 15px; color: #2b8df0">{{ `(${item.mark}分)` }}</span>
        </div>
        <!-- 单选题 -->
        <van-radio-group class="singleChoice"
                         v-model="item.answer"
                         v-if="bigType.qsType == '单选题'">
          <van-radio :name="abList[opIdx]"
                     disabled
                     v-for="(opt, opIdx) in item.options"
                     :key="opIdx"><span class="iico">{{ abList[opIdx] }}</span>{{ opt.opTit }}</van-radio>
        </van-radio-group>
        <!-- 多选题 -->
        <van-checkbox-group v-model="item.answer"
                            v-else-if="bigType.qsType == '多选题'">
          <van-checkbox :name="abList[opIdx]"
                        disabled
                        v-for="(opt, opIdx) in item.options"
                        :key="opIdx"><span class="iico">{{ abList[opIdx] }}</span>{{ opt.opTit }}</van-checkbox>
        </van-checkbox-group>
        <!-- 判断题 -->
        <van-radio-group class="judge"
                         v-model="item.answer"
                         v-if="bigType.qsType == '判断题'">
          <van-radio disabled
                     name="1">{{
            $t("checkOutAnswer.AnswerTxt1")
          }}</van-radio>
          <van-radio disabled
                     name="0">{{
            $t("checkOutAnswer.AnswerTxt2")
          }}</van-radio>
        </van-radio-group>
        <!-- 填空题 -->
        <!-- v-if="item.answer.length != 0 && bigType.qsType == '填空题'" -->
        <div class="score"
             v-if="bigType.qsType == '填空题'"
             :style="{ color: item.score == '正确' ? '#2B8DF0' : '#F53838' }">
          <span><span style="color: #2b8df0">答对{{ tkRorW[index].rightNum }}空</span><span style="color: #333">/</span><span style="color: #f53838">答错{{ tkRorW[index].wrongNum }}空</span></span>
          <span :style="{
              color: tkRorW[index].rightNum > 0 ? '#2B8DF0' : '#F53838',
            }">+{{ tkRorW[index].rightNum * bigType.mark }}分</span>
        </div>
        <van-cell-group v-if="bigType.qsType == '填空题'"
                        class="tk">
          <div v-for="(tk, tkIdx) in item.answer"
               :key="tkIdx">
            <van-field v-model="tk.daAn"
                       readonly
                       :label="$t('checkOutAnswer.AnswerTxt3') + parseInt(tkIdx + 1)">
              <template #left-icon>
                <span v-if="tk.daAn == tk.zqDaAn">
                  <svg-icon icon-class="checkOutAnswer-1"></svg-icon>
                </span>
                <span v-else>
                  <svg-icon icon-class="checkOutAnswer-2"></svg-icon>
                </span>
              </template>
            </van-field>
            <van-field v-if="can_show_correct_res"
                       class="zqdn"
                       v-model="tk.zqDaAn"
                       readonly
                       :label="$t('checkOutAnswer.AnswerTxt4')" />
          </div>
        </van-cell-group>
        <!-- 简答题 -->
        <van-field class="brief"
                   v-if="bigType.qsType == '问答题'"
                   v-model="item.answer"
                   rows="5"
                   autosize
                   label=""
                   readonly
                   type="textarea"
                   show-word-limit
                   style="margin-bottom: 15px" />
        <!-- 上传题 -->
        <!-- <van-uploader v-if="bigType.qsType == '附件题'"
                      v-model="item.answer"
                      multiple
                      :max-count="1">
          <van-button icon="plus"
                      type="info">上传文件</van-button>
        </van-uploader> -->
        <!-- 评分 -->
        <div class="score"
             v-if="item.score != '' && bigType.qsType != '填空题'"
             :style="{ color: item.score == '正确' ? '#2B8DF0' : '#F53838' }">
          <span v-if="item.score == '正确'">
            <svg-icon icon-class="checkOutAnswer-1"></svg-icon>
            {{ $t("checkOutAnswer.AnswerTxt5") }}
          </span>
          <span v-else-if="item.score == '错误'">
            <svg-icon icon-class="checkOutAnswer-2"></svg-icon>
            {{ $t("checkOutAnswer.AnswerTxt6") }}
          </span>
          <!-- <span>+5分</span> -->
        </div>
        <!-- 简答和附件需要人工评分 -->
        <!-- <div class="score"
             v-if="item.score == ''"
             style="margin-top:15px;color:#2B8DF0;">
          <span>
            需要人工判分
          </span>
          <span>待判分</span>
        </div> -->
        <!-- 解析 -->
        <div class="analysis"
             v-if="item.score != '' && bigType.qsType != '填空题' && can_show_correct_res">
          <div class="zqda"
               v-if="item.score == '错误'">
            <span style="color: #2b8df0">{{
              $t("checkOutAnswer.AnswerTxt7")
            }}</span>
            <p>{{ item.stdqsres }}</p>
          </div>
          <div class="zqda"
               v-else>
            <span style="color: #2b8df0">{{
              $t("checkOutAnswer.AnswerTxt7")
            }}</span>
            <p>{{ item.stdqsres }}</p>
          </div>
          <!-- <div class="jiexi">
            <span>答案解析：</span>
            <p v-if="item.remark == ''">暂无解析</p>
            <p>{{ item.remark }}</p>
          </div> -->
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { Notify } from "vant";
import { getCourseTestTotal, getTestFinishInfo } from "@api/wxpx.js";
export default {
  data () {
    return {
      userInfo: JSON.parse(localStorage.userInfo) || {},
      paperid: this.$route.query.paperid,
      mautoid: this.$route.query.mautoid, // 培训实施记录id
      teststyle: this.$route.query.teststyle, // 培训实施记录id
      examinTitle: "", // 考试标题
      answerList: [], // 试题及答案
      can_show_correct_res: true,
      bigOrder: [
        this.$t("checkOutAnswer.AnswerTxt8"),
        this.$t("checkOutAnswer.AnswerTxt9"),
        this.$t("checkOutAnswer.AnswerTxt10"),
        this.$t("checkOutAnswer.AnswerTxt11"),
        this.$t("checkOutAnswer.AnswerTxt12"),
        this.$t("checkOutAnswer.AnswerTxt13"),
        this.$t("checkOutAnswer.AnswerTxt14"),
      ],
      abList: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"],
      qsList: [
        // {// 0单选，1多选，2判断，3填空，4问答，5附件
        //   qsType: "单选题",
        //   flag: 0,
        //   mark: "5",
        //   qsDetails: [],
        // },
        // {
        //   qsType: "多选题",
        //   flag: 1,
        //   mark: "5",
        //   qsDetails: [],
        // },
        // {
        //   qsType: "判断题",
        //   flag: 2,
        //   mark: "5",
        //   qsDetails: [],
        // },
        // {
        //   qsType: "填空题",
        //   flag: 3,
        //   mark: "5",
        //   qsDetails: [],
        // },
        // {
        //   qsType: "问答题",
        //   flag: 4,
        //   qsDetails: [],
        // },
        // {
        //   qsType: "附件题",
        //   flag: 5,
        //   qsDetails: [
        //     {
        //       qsTit: "请上传冒泡排序的代码？",
        //       answer: [],
        //       mark: 5,
        //       score: "",
        //     },
        //     {
        //       qsTit: "请上传一个轮播图的代码？",
        //       answer: [],
        //       mark: 5,
        //       score: "",
        //     },
        //   ],
        // },
      ],
    };
  },
  computed: {
    // 计算填空题有多少个空
    tkNum () {
      let num = 0;
      if (this.qsList.length > 0)      {
        let ary = this.qsList.filter((qs) => {
          return qs.qsType == "填空题";
        });
        if (ary[0].qsDetails.length > 0)        {
          // 说明有填空题
          ary[0].qsDetails.forEach((tk) => {
            num += tk.qsTit.split("____").length - 1;
          });
        }
      }
      return num;
    },
    // 计算简答题的总分
    brief () {
      let num = 0;
      if (this.qsList.length > 0)      {
        let ary = this.qsList.filter((qs) => {
          return qs.qsType == "问答题";
        });
        if (ary[0].qsDetails.length > 0)        {
          // 说明有简答题
          ary[0].qsDetails.forEach((wd) => {
            num += wd.mark;
          });
        }
      }
      return num;
    },
    // 计算填空题有多少道对的和多少到错误的题
    tkRorW () {
      let arys = [];
      if (this.qsList.length > 0)      {
        let ary = this.qsList.filter((qs) => {
          return qs.qsType == "填空题";
        });
        // console.log(ary,'ttttttttkkkkkkkk')
        if (ary[0].qsDetails.length > 0)        {
          // 说明有填空题
          ary[0].qsDetails.forEach((tk) => {

            let o = { rightNum: 0, wrongNum: 0 };
            tk.answer.forEach((an) => {
              if (an.daAn == an.zqDaAn)              {
                o.rightNum += 1;
              } else              {
                o.wrongNum += 1;
              }
            });
            arys.push(o);
          });
        }
      }
      return arys;
    },
  },
  methods: {
    // 点击返回
    onClickLeft () {
      if (this.$route.query.returnPath == "examRecord")      {
        this.$router.push("/examRecord");
      } else      {
        this.$router.push("/home");
      }
    },
    // 查询课程考试信息汇总
    GetCourseTestTotal () {
      getCourseTestTotal({ paperid: this.paperid }).then((res) => {
        if (res.iserror == 0)        {

          this.can_show_correct_res = res.baseinfo[0].can_show_correct_res;
          this.examinTitle = res.baseinfo[0].papername;
          res.data.forEach((el) => {
            let obj = {};
            if (el.qsflagname == "单选")            {
              obj = {
                qsType: "单选题",
                flag: 0,
                mark: el.score,
                qsDetails: [],
              };
              this.qsList.push(obj);
            } else if (el.qsflagname == "多选")            {
              obj = {
                qsType: "多选题",
                flag: 1,
                mark: el.score,
                qsDetails: [],
              };
              this.qsList.push(obj);
            } else if (el.qsflagname == "判断")            {
              obj = {
                qsType: "判断题",
                flag: 2,
                mark: el.score,
                qsDetails: [],
              };
              this.qsList.push(obj);
            } else if (el.qsflagname == "填空")            {
              obj = {
                qsType: "填空题",
                flag: 3,
                mark: el.score,
                qsDetails: [],
              };
              this.qsList.push(obj);
            } else if (el.qsflagname == "问答")            {
              obj = {
                qsType: "问答题",
                flag: 4,
                qsDetails: [],
              };
              this.qsList.push(obj);
            }
          });
          this.GetTestFinishInfo();
        }
      });
    },
    // 获取试卷提交后的所有试题答案明细信息
    async GetTestFinishInfo () {
      await getTestFinishInfo({
        mautoid: this.mautoid,
        empid: this.userInfo.empid,
        username: this.userInfo.username, //后续登录接口有变--username
        teststyle: this.teststyle,
        paperid: this.paperid
      }).then((res) => {
        if (res.iserror == 0)        {
          this.answerList = res.data;
          // console.log(this.answerList, 'this.answerList,this.answerList');

          this.qsList.forEach((el) => {
            res.data.forEach((item) => {
              let obj = {};
              if (el.flag == item.qsflag && item.qsflag == 0)              {
                // 单选
                let options = [];
                let spi = item.qsitemlist.split("|");
                spi.forEach((sp) => {
                  let oo = {
                    opTit: sp,
                  };
                  options.push(oo);
                });
                obj = {
                  autoid: item.autoid,
                  qsTit: item.qstitle,
                  answer: item.qsres,
                  stdqsres: item.stdqsres,
                  score: item.qsres == item.stdqsres ? "正确" : "错误", //回答是否正确
                  options: options,
                  get: item.score ? item.score : 0,
                  remark: item.remark,
                };
                el.qsDetails.push(obj);
              } else if (el.flag == item.qsflag && item.qsflag == 1)              {
                // 多选
                let options = [];
                let spi = item.qsitemlist.split("|");
                spi.forEach((sp) => {
                  let oo = {
                    opTit: sp,
                  };
                  options.push(oo);
                });
                obj = {
                  autoid: item.autoid,
                  qsTit: item.qstitle,
                  answer: item.qsres ? item.qsres.split(",") : ["A", "B"],
                  stdqsres: item.stdqsres,
                  score: item.qsres == item.stdqsres ? "正确" : "错误", //回答是否正确
                  options: options,
                  get: item.score ? item.score : 0,
                  remark: item.remark,
                };
                el.qsDetails.push(obj);
              } else if (el.flag == item.qsflag && item.qsflag == 2)              {
                // 判断
                obj = {
                  autoid: item.autoid,
                  qsTit: item.qstitle,
                  answer:
                    item.qsres == "true"
                      ? "1"
                      : item.qsres == "false"
                        ? "0"
                        : "",
                  stdqsres: item.stdqsres == "true" ? "正确" : "错误",
                  score: item.qsres == item.stdqsres ? "正确" : "错误", //回答是否正确
                  // options: item.qsitemlist.split('|'),
                  get: item.score ? item.score : 0,
                  remark: item.remark,
                };
                el.qsDetails.push(obj);
              } else if (el.flag == item.qsflag && item.qsflag == 3)              {
                // 填空
                let answer = [];
                let zqary = item.stdqsres ? item.stdqsres.split("|") : [];
                let da = item.qsres ? item.qsres.split("|") : [];
                if (
                  zqary.length > 0
                )                {
                  for (let i = 0; i < zqary.length; i++)                  {
                    let oo = {
                      daAn: da[i],
                      zqDaAn: zqary[i],
                      check: da[i] == zqary[i],
                    };
                    answer.push(oo);
                  }
                }
                obj = {
                  autoid: item.autoid,
                  qsTit: item.qstitle,
                  answer: answer,
                  stdqsres: item.stdqsres,
                  // score: item.qsres == item.stdqsres ? "正确" : "错误", //回答是否正确
                  // options: item.qsitemlist.split('|'),
                  get: item.score ? item.score : 0,
                  remark: item.remark,
                };
                el.qsDetails.push(obj);
              } else if (el.flag == item.qsflag && item.qsflag == 4)              {
                // 问答
                obj = {
                  autoid: item.autoid,
                  qsTit: item.qstitle,
                  answer: item.qsres,
                  stdqsres: item.stdqsres,
                  score: item.score ? "正确" : "错误", //回答是否正确
                  // options: item.qsitemlist.split('|'),
                  get: item.score ? item.score : 0,
                  remark: item.remark,
                };
                el.qsDetails.push(obj);
              }
            });
          });
          // console.log(this.qsList, 'this.qsList,this.qsList');
        }
      });
    },
  },
  created () {
    window.T = this;
    this.GetCourseTestTotal();
  },
};
</script>

<style lang="less" scoped>
.checkOutAnswer {
  background: #fff;
  height: 100vh;
  /deep/.van-radio__label--disabled,
  /deep/.van-checkbox__label--disabled {
    color: #333;
  }
  /deep/.van-radio__icon--disabled.van-radio__icon--checked .van-icon,
  /deep/.van-checkbox__icon--disabled.van-checkbox__icon--checked .van-icon {
    color: #fff;
    background-color: #1989fa;
    border-color: #1989fa;
  }

  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .banner {
    height: 124px;
    background: url("~@/assets/img/examination1.png") no-repeat;
    background-size: cover;
    background-position-y: -150px;
  }
  .content {
    font-size: 36px;
    font-family: Source Han Sans SC;
    padding: 30px 0;
    border-bottom: 1px solid #ccc;
    background: #fff;
    .qsType {
      margin-bottom: 28px;
      border-bottom: 1px solid #ccc;
      padding: 0 30px;
      padding-bottom: 28px;
      .typeName {
        margin-right: 15px;
      }
      .grade {
        color: #2b8df0;
      }
    }
    .qsContent {
      padding: 36px 0;
      padding: 0 30px;
      color: #333;
      font-size: 32px;
      margin-bottom: 30px;
      .qsTit {
        margin-bottom: 42px;
      }
      // 单选样式
      .singleChoice {
        padding-left: 1em;
        .van-radio {
          margin-bottom: 40px;
        }
        .van-radio {
          /deep/ .van-radio__icon--round {
            display: none;
          }
          /deep/.van-radio__label {
            margin-left: 0px;
          }
          /deep/ .iico {
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            background: #ebebeb;
            border-radius: 50%;
            display: inline-block;
            margin-right: 16px;
          }
        }
        .van-radio[aria-checked="true"] {
          /deep/ .iico {
            display: none;
          }
          /deep/ .van-radio__icon--round {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin-right: 16px;
            .van-icon {
              width: 100%;
              height: 100%;
              line-height: 60px;
              text-align: center;
            }
          }
        }
      }
      // 多选样式
      .van-checkbox-group {
        padding-left: 1em;
        .van-checkbox {
          margin-bottom: 40px;
        }
        .van-checkbox {
          /deep/ .van-checkbox__icon--round {
            display: none;
          }
          /deep/.van-checkbox__label {
            margin-left: 0px;
          }
          /deep/ .iico {
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            background: #ebebeb;
            border-radius: 50%;
            display: inline-block;
            margin-right: 16px;
          }
        }
        .van-checkbox[aria-checked="true"] {
          /deep/ .iico {
            display: none;
          }
          /deep/ .van-checkbox__icon--round {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin-right: 16px;
            .van-icon {
              width: 100%;
              height: 100%;
              line-height: 60px;
              text-align: center;
            }
          }
        }
      }
      // 判断题样式
      .judge {
        padding-left: 1em;
        .van-radio {
          margin-bottom: 40px;
          /deep/ .van-radio__icon--round {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin-right: 16px;
            .van-icon {
              width: 100%;
              height: 100%;
              line-height: 60px;
              text-align: center;
            }
          }
        }
      }
      // 填空题样式
      .tk {
        div {
          .van-cell {
            /deep/.van-cell__title {
              width: 4em;
              margin-right: 0;
            }
          }
          .zqdn {
            /deep/.van-cell__title {
              width: 5.3em;
              margin-right: 0;
            }
          }
        }
      }
      // 简答题样式
      .brief {
        display: flex;
        flex-direction: column;
        border: 1px solid #ccc;
      }
      // 上传题样式
      /deep/.van-uploader {
        width: 100%;
        /deep/.van-uploader__wrapper {
          display: flex;
          justify-content: flex-end;
          flex-flow: row-reverse;
          // flex-direction: column-reverse;
          /deep/.van-uploader__input-wrapper {
            // background: red;
            width: 50%;
            // width:80%;
          }
        }
      }
      // 评分样式
      .score {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 64px;
        background: #e6e6e6;
        border-radius: 42px;
        padding: 0 15px;
        font-size: 32px;
        margin-bottom: 16px;
        span:first-child {
          display: flex;
          align-items: center;
        }
        .svg-icon {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
      }
      // 答案解析样式
      .analysis {
        background: #e6e6e6;
        border-radius: 42px;
        padding: 30px;
        font-size: 32px;
        font-family: Source Han Sans SC;
        color: #333;
      }
    }
  }
}
</style>
